<template>
  <div id="nav" :class="$style.header">
    <div :class="$style.title">
      <ul :class="$style.breadcrumb">
        <li>
          <router-link to="/">Меню</router-link>
        </li>
        <li v-for="(link, index) in curBreadcrumb" :key="index">
          <span v-if="index === curBreadcrumb.length - 1">
            {{ link.text }}
          </span>
          <router-link v-else :to="link.to">
            {{ link.text }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LandingHeader',
  data() {
    return {
      breadcrumb: {
        SignIn: [{ text: 'Вход в AddSellers', to: '/sellers/sign-in' }],
        LandingMenu: [{ text: 'Меню лендингов', to: '/landings' }],
        landingCreate: [
          { text: 'Cписок лендингов', to: '/landings' },
          { text: 'Создание лендинга', to: '/landings/create' }
        ],
        landingList: [{ text: 'Список лендингов', to: '/landings' }],
        landingEdit: [
          { text: 'Список лендингов', to: '/landings' },
          { text: 'Редактирование', to: '/landings/:id' }
        ],

        default: [{ text: 'Список лендингов', link: '/landings' }]
      }
    }
  },
  computed: {
    curBreadcrumb() {
      return this.breadcrumb[this.$route.name] || this.breadcrumb.default
    },
    isAuth() {
      return this.$store.getters.isAuth
    }
  }
}
</script>

<style lang="scss" module>
.header {
  @include baseShadow;
  display: flex;
  justify-content: space-between;
  background: $white;
  padding: 0.75rem 5rem;
  position: sticky;
  top: 0;
  z-index: 1000;
  .breadcrumb {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li {
      list-style: none;
      &:not(:first-child) {
        margin-left: 0.688rem;
        &::before {
          content: '/';
          color: $dark-gray;
        }
      }
      > a {
        transition: all 0.5s ease;
        &:hover {
          color: $black-gray;
        }
      }
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span,
    a {
      font-size: 1.2rem;
      font-weight: bold;
      color: $dark-gray;
    }

    a {
      text-decoration: none;
      transition: all 0.3s ease-in-out;

      &:hover,
      &.active {
        color: $black-gray;
      }
    }
  }
  svg {
    height: 2rem;
    width: 2rem;
    cursor: pointer;
  }
}
</style>
