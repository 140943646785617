<template>
  <div>
    <Header />
    <transition name="page">
      <router-view />
    </transition>
  </div>
</template>

<script>
import Header from "@/components/organisms/Header";
export default {
  components: {
    Header,
  },
};
</script>
